<template>
  <div>
    <q-toolbar class="column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm">{{ $t('campaigns') }}</q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el :label="$t('dashboard')" to="/ " />
        <q-breadcrumbs-el :label="$t('campaigns')" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <q-toolbar class="q-pr-none q-mb-md">
      <q-space />
      <q-btn
        outline
        icon="mdi-filter-outline"
        color="primary"
        no-caps
        class="q-px-sm q-mx-sm"
        label="Filtrar"
        @click="filter = !filter"
      >
        <q-tooltip> Filtrar Campanhas </q-tooltip></q-btn
      >
      <q-btn outline color="primary" icon-right="archive" class="q-mr-sm" no-caps @click="exportCsv" unelevated>
        <q-tooltip> Exportar como csv </q-tooltip>
      </q-btn>
      <q-btn
        unelevated
        color="primary"
        class="q-px-lg"
        icon="mdi-plus"
        label="Adicionar"
        no-caps
        to="/campaigns/manage"
      />
      <!-- @click="ActionSetDialogCampaign(true)" -->
    </q-toolbar>
    <q-card flat v-if="filter" bordered class="q-mb-sm">
      <q-toolbar>
        <!-- <q-toolbar-title> {{ $t("filter") }} </q-toolbar-title> -->
        <q-btn flat round dense class="q-ml-auto" icon="mdi-close" @click="filter = false" />
      </q-toolbar>
      <q-card-section class="q-pt-none row q-col-gutter-md">
        <div class="col-3">
          <!-- <span class="text-weight-medium">ID da Campanha</span> -->
          <q-input
            outlined
            label="ID da Campanha"
            :disable="disable_idCampaing"
            v-model="params.idCampaign"
            type="text"
          />
        </div>
        <div class="col-3">
          <select-request
            :v-model="params.campaign"
            label="Campanha"
            outlined
            endpoint="/redirect?app=ADVERTISER&path=/api/get/campaign/all"
            option-label="name"
            option-value="campaign_id"
            clearable
            @update="updateCampaingSelect"
          />
        </div>
        <div class="col-3">
          <!-- endpoint="get/profile" -->
          <!-- <select-request
            label="Gerente"
            :v-model="params.managerId"
            outlined
            endpoint="user/user-by-profile/2"
            option-label="name"
            option-value="user_id"
            clearable
            @update="(value) => (params.managerId = value)"
          /> -->
          <select-request
            label="Gerente"
            :v-model="params.managerId"
            outlined
            endpoint="user/managers"
            option-label="name"
            option-value="user_id"
            clearable
            @update="(value) => (params.managerId = value)"
          />
        </div>
        <div class="col-3">
          <select-request
            :v-model="params.advertiserId"
            label="Anunciante / Agência"
            outlined
            endpoint="redirect?app=ADVERTISER&path=/api/get/advertiser/filter/all"
            option-label="trademark"
            option-value="id"
            clearable
            @update="(value) => (params.advertiserId = value)"
          />
        </div>
        <div class="col-5">
          <select-request
            label="Categoria"
            :v-model="params.categoryId"
            outlined
            endpoint="redirect?app=ADVERTISER&path=/api/get/campaign/category/filter"
            option-label="name"
            option-value="id"
            clearable
            @update="(value) => (params.categoryId = value)"
          />
        </div>
        <div class="col-5">
          <q-input outlined label="Action ID" v-model="params.actionId" type="text" />
        </div>
        <div class="col-8">
          <q-card flat>
            <q-card-section class="q-pa-none">
              <span class="text-weight-medium">Status</span>
              <div class="q-gutter-sm">
                <q-checkbox v-model="params.status" val="1" label="Ativados" color="primary" />
                <q-checkbox v-model="params.status" val="2" label="Pausados" color="accent" />
                <q-checkbox v-model="params.status" val="3" label="Desativados" color="negative" />
                <q-checkbox v-model="params.status" val="0" label="Pendentes" color="warning" />
                <q-checkbox v-model="params.status" val="4" label="em Implementação" color="purple" />
              </div>
            </q-card-section>
          </q-card>
          <!-- <div class="q-gutter-sm">
            <q-checkbox v-model="params.status.active" label="Ativados" color="primary" />
            <q-checkbox v-model="params.status.paused" label="Pausados" color="accent" />
            <q-checkbox v-model="params.status.desactive" label="Desativados" color="negative" />
            <q-checkbox v-model="params.status.pending" label="Pendentes" color="warning" />
          </div> -->
        </div>
        <div class="col-4">
          <q-card flat>
            <q-card-section class="q-pa-none">
              <span class="text-weight-medium">Tipo</span>
              <div class="q-gutter-sm">
                <q-checkbox v-model="params.type" val="CPL" label="CPL" color="orange" />
                <q-checkbox v-model="params.type" val="CPA" label="CPA" color="teal" />
                <q-checkbox v-model="params.type" val="CPC" label="CPC" color="cyan" />
              </div>
            </q-card-section>
          </q-card>
        </div>
        <div class="col-3">
          <q-card flat>
            <q-card-section class="q-pa-none">
              <div class="q-gutter-sm">
                <q-checkbox
                  v-model="params.is_test"
                  :false-value="true"
                  :true-value="false"
                  :label="$t('dont_show_test_campaign')"
                />
              </div>
            </q-card-section>
          </q-card>
        </div>

        <div class="col-9 text-right">
          <!--          <q-btn-->
          <!--            color="primary"-->
          <!--            label="Limpar"-->
          <!--            no-caps-->
          <!--            outline-->
          <!--            class="q-px-md q-mx-md"-->
          <!--            @click="clearFilter"-->
          <!--          />-->
          <q-btn color="positive" label="Buscar" no-caps unelevated class="q-px-md" @click="onFilterCampaigns" />
        </div>
      </q-card-section>
    </q-card>
  </div>
</template>

<script>
import CampaignMixin from '../../mixins/CampaignMixin';
import AdvertiserMixin from '@/mixins/AdvertiserMixin.js';
import SelectRequest from '@/widgets/inputsGlobal/SelectRequest.vue';

export default {
  name: 'HeaderCampaign',
  mixins: [CampaignMixin, AdvertiserMixin],
  components: { SelectRequest },
  data() {
    return {
      filter: true,
      params: {
        idCampaign: null,
        campaign: null,
        managerId: null,
        advertiserId: null,
        categoryId: null,
        actionId: null,
        status: [],
        type: [],
        is_test: false
      },
      disable_idCampaing: false
    };
  },
  methods: {
    updateCampaingSelect(value) {
      if (value) {
        this.disable_idCampaing = true;
        this.params.idCampaign = value.id;
        this.params.campaign = value;
      } else {
        this.params.idCampaign = null;
        this.disable_idCampaing = false;
        this.params.campaign = null;
      }
    },

    clearFilter() {
      this.params = {
        idCampaign: null,
        campaign: null,
        managerId: null,
        advertiserId: null,
        categoryId: null,
        status: [],
        type: []
      };
      this.ActionSetFilterCampaign();
      this.getCampaigns();
    },
    onFilterCampaigns() {
      let filterSelect = [];

      if (this.params.campaign) filterSelect.push(`id=${this.params.campaign.id}`);
      else if (this.params.idCampaign) filterSelect.push(`id=${this.params.idCampaign}`);
      if (this.params.managerId) filterSelect.push(`managerId=${this.params.managerId.user_id}`);
      if (this.params.advertiserId) filterSelect.push(`advertiserId=${this.params.advertiserId.id}`);
      if (this.params.categoryId) filterSelect.push(`categoryId=${this.params.categoryId.id}`);
      if (this.params.actionId) filterSelect.push(`action_id=${this.params.actionId}`);
      if (this.params.type.length)
        filterSelect.push(`comissionType=[${this.params.type.map((item) => (item = `"${item}"`))}]`);
      if (this.params.status.length)
        filterSelect.push(`status=[${this.params.status.map((item) => (item = `"${item}"`))}]`);
      if (this.params.is_test === false) filterSelect.push(`is_test=0`);

      // Needs to change de '&' to %26 to work
      this.ActionSetFilterCampaign(filterSelect.join('%26'));
      this.getCampaigns();
    },

    async exportCsv() {
      try {
        this.onLoading(true);

        const { data } = await this.searchCampaign(1, 1, this.filterCampaign + '%26showAll%3Dtrue');

        this.exportTableCsv(this.prepareItemCSV(data), this.manageCampaignsVisibleColumns);
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    }
  }
};
</script>

<style></style>
